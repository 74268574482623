<div>
    <div class="d-flex align-items-center pb-3" style="border-bottom: 1px solid #EBF3FF;">
        <span class="d-block custom-text-14 mr-2">
            <img src="assets/img/basic-services-companies-logos-2/AutoTag.png" onerror="this.onerror=null;this.remove()"
                style="max-width: 80px;">
        </span>
        <div class="d-block">
            <div>
                <span style="color: #3B4086;
                font-family: Poppins;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;">Deuda actual de AutoTag</span>
            </div>
            <div style="color: #868686;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;">
                {{debtSummary.clientNumber}}
            </div>
        </div>
    </div>
    <div style="
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      width: 100%;
      background: #FFECD1;
      padding: 16px;
      flex: none;
      border-radius: 8px;">
        <div style="display: flex; flex-direction: column; align-items: flex-start; gap: 4px;">
            <div style="font-weight: 700; font-size: 14px; color: #FF9E1B;">⚠️ Por el momento, AutoTag solo está operando:</div>
            <div class="description">
                <div class="icon"></div>
            <div style="display: flex; flex-direction: column; align-items: flex-start;">
              <div style="color: #444C8A;"> - Autovía Santiago Lampa</div>
              <div style="color: #444C8A;"> - Autopistas AVO</div>
              <div style="color: #444C8A;"> - Vespucio Norte</div>
              <div style="color: #444C8A;"> - Autopase - Autopista Central</div>
            </div>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-between py-3" style="border-bottom: 1px solid #EBF3FF;">
        <span style="color: #9D9FC3;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;">
            Esta cuenta contempla {{debtSummary.currentDebt.invoices.length > 1 ?
            debtSummary.currentDebt.invoices.length + ' deudas' : debtSummary.currentDebt.invoices.length + ' deuda'}}:
        </span>
        <span *ngIf="debtSummary.currentDebt.invoices.length > 1" style="color: #3B4086;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;">
            {{debtSummary.currentDebt.totalAmount | clpCurrency}}
        </span>
    </div>
    <div *ngFor="let invoice of debtSummary.currentDebt.invoices; let i = index;"
        style="border-bottom: 1px solid #EBF3FF;" class="d-flex align-items-center justify-content-between py-2">
        <div class="d-flex align-items-center">
            <div *ngIf="invoice?.providerIdentifier">
                <img src="{{getHighwayLogo(invoice?.providerIdentifier)}}" onerror="this.onerror=null;this.remove()"
                    class="mr-3" style="max-width: 80px;">
            </div>
            <div>
                <span class="d-block" style="color: #3C4082;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;">
                    {{invoice?.companyName}}
                </span>
                <span style="color: #868686;
                text-align: center;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 19px; /* 135.714% */">
                    Vence el {{invoice?.dueDate}}
                </span>
            </div>
        </div>
        <div>
            <span class="d-block" style="color: #3B4086;
            text-align: right;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 19px; /* 118.75% */
            letter-spacing: -0.032px;">{{invoice.amount | clpCurrency}}</span>
        </div>
    </div>
    <div>
        <span *ngIf="totalDebt && debtSummary.currentDebt.invoices.length > 1" class="mt-3 mx-2 d-block" style="color: #868686;
        text-align: center;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;">*Estas deudas no se pueden pagar individualmente. Si necesitas hacer pagos individuales,
            debes agregar cada autopista por separado.</span>
    </div>
    <div class="d-flex justify-content-center w-100 mt-4 mb-2">
        <button *ngIf="firstDebt || totalDebt" mat-raised-button class="confirm-btn"
            [ngClass]="!isSelectionValid ? 'opacity-btn' : ''" (click)="saveSelectedInvoices()"
            [disabled]="!isSelectionValid">Aceptar</button>
    </div>
</div>
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  AngularFireStorage,
  AngularFireUploadTask
} from '@angular/fire/compat/storage';
import firebase from 'firebase/compat';
import { deleteField } from 'firebase/firestore';

import { DevicesDetectorService } from '../devices-detector/devices-detector.service';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {
  userTokenId: string;
  lastSignInTime: any;
  constructor(
    private angularFirestore: AngularFirestore,
    private angularFireStorage: AngularFireStorage,
    private deviceService: DevicesDetectorService,
  ) {}

  firestoreNewDoc(
    col: string,
    data: firebase.firestore.DocumentData,
    docID: string = null
  ): Promise<void> {
    if (docID === null) {
      return this.angularFirestore.firestore
        .collection(col)
        .doc()
        .set(data);
    } else {
      return this.angularFirestore.firestore
        .collection(col)
        .doc(docID)
        .set(data);
    }
  }

  firestoreUpdateDoc(
    col: string,
    data: firebase.firestore.UpdateData,
    docID: string
  ): Promise<void> {
    return this.angularFirestore.firestore
      .collection(col)
      .doc(docID)
      .update(data);
  }

  NeatClubSignTerms(
    docID: string
  ): Promise<void> {
    return this.angularFirestore.firestore
      .collection('neatClubUsersInfo')
      .doc(docID)
      .update({'clubConditions.hasSignedTyC': true });
  }

  deleteNeatAutoFields(col: string, docID: string): void {
    this.angularFirestore.firestore
      .collection(col)
      .doc(docID)
      .update({
        'paymentOption.automaticType': deleteField(),
        'automaticPaymentConfiguration': deleteField() // Uso del método deleteField() del SDK modular
      });
  }

  firestoreUpdateEntity(
    col: string,
    data: firebase.firestore.UpdateData,
    docID: string
  ): Promise<void> {
    try {
      this.addChangeLog(col, docID);
    } catch (err) {}
    return this.angularFirestore
      .doc(`${col}/${docID}`)
      .set(data, { merge: true });
  }

  async addChangeLog(col: string, docID: string): Promise<void> {
    try {
      const changeLogId = this.angularFirestore.createId();
      const ipAddress = await this.getIPAddress();
      this.angularFirestore.doc(`${col}/${docID}/changeLog/${changeLogId}`).set({
        date: new Date(new Date().toLocaleString('en-US', { timeZone: 'America/Santiago' })),
        device: navigator.userAgent || null,
        platform: this.deviceService.isMobile() ? 'Responsive' : 'Web',
        lastSignInTime: new Date(new Date(this.lastSignInTime).toLocaleString('en-US', { timeZone: 'America/Santiago' })) || null,
        userToken: {
          tokenId: this.userTokenId || null,
          ip: ipAddress || null
        }
      }, { merge: true });
    } catch (err) {}
  }

  async getIPAddress() {
    try {
      const response = await fetch('https://api64.ipify.org?format=json');
      const data = await response?.json();
      return data?.ip || null;
    } catch (err) {}
  }

  firestoreDeleteDoc(col: string, docID: string): Promise<void> {
    return this.angularFirestore.firestore
      .collection(col)
      .doc(docID)
      .delete();
  }

  storageNewBucket(path: string): AngularFireUploadTask {
    return this.angularFireStorage.ref(path).putString('');
  }

  firestoreGetData(
    collectionName: string,
    docID: string
  ): Promise<firebase.firestore.DocumentSnapshot> {
    return this.angularFirestore.firestore
      .collection(collectionName)
      .doc(docID)
      .get();
  }

  firestoreGetCollection(
    collectionName: string
  ) {
    return this.angularFirestore.firestore
      .collection(collectionName)
      .get();
  }

  firestoreGetRef(
    collectionName: string,
    docID: string
  ): firebase.firestore.DocumentReference {
    return this.angularFirestore.firestore
      .collection(collectionName)
      .doc(docID);
  }

  // Corresponde a la tarea de subida del archivo,
  // que permitirá revisar el porcentaje de carga del archivo
  storageUploadFile(
    path: string,
    file,
    metadata: firebase.storage.UploadMetadata
  ): AngularFireUploadTask {
    return this.angularFireStorage.ref(path).put(file, metadata);
  }

  firestoreQueryDocs(
    col: string,
    parameter: string | firebase.firestore.FieldPath,
    comparison: firebase.firestore.WhereFilterOp,
    value
  ): firebase.firestore.Query {
    // Use Get to get the results once, use onSnapshot place a listener
    return this.angularFirestore.firestore
      .collection(col)
      .where(parameter, comparison, value);
  }
}

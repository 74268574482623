import { DatePipe, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Severity } from '@sentry/browser';
import { CustomProvidersNames, customProvidersDayPaymentLimits, SencillitoAutomaticPreferences } from 'neat-lib/dist/Enums/Constants';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import Swal from 'sweetalert2';

import { CheckoutService } from '@services/checkout/checkout.service';
import { CookiesService } from '@services/cookies/cookies.service';
import { RegisterCreditCardService } from '@services/credit-card-registration/register-credit-card.service';
import { PaymentsService } from '@services/payments/payments.service';
import { RentListService } from '@services/rent-list/rent-list.service';
import { DatabaseService } from '@services/shared/database/database.service';
import { DialogService } from '@services/shared/dialog/dialog.service';
import { ErrorHandlerService } from '@services/shared/error-handler/error-handler.service';
import { SweetalertService } from '@services/shared/sweetalert/sweetalert.service';
import { UserService } from '@services/user/user.service';
import { AppGlobals } from '@shared/constants';
import {
  BasicServiceType, CardTypes, EntityType, EntityTypeLocale, IconErrorModals,
  IconInfoModals, PayingAccountUnit, paymentMethodsOptions, ProfessionalService
} from '@shared/enums/enums.enum';
import { MyErrorStateMatcher } from '@shared/my-error-state-matcher/my-error-state-matcher.directive';
import { MergeTypes, canAutomatizatePayment, formatEdipro, today } from '@shared/utils';
import { EntityClass } from 'app/dashboard/checkout/entity';
import { PaymentClass } from 'app/dashboard/checkout/payment';
import { FormValidation } from 'app/landing/register/form-validation';
import { BasicService } from 'app/models/abstract-basic-service.model';
import { Entity } from 'app/models/abstract-entity.model';
import { User } from 'app/models/users/user.model';
import { ClpCurrencyPipe } from 'app/pipes/clp-currency/clp-currency.pipe';


@Component({
  selector: 'app-automate-payment',
  templateUrl: './automate-payment.component.html',
  styleUrls: ['./automate-payment.component.scss'],
  providers: [ClpCurrencyPipe, DatePipe],
})
export class AutomatePaymentComponent implements OnInit {
  showLoading = false;
  editAutomaticEntity = false;
  currentUser: User;
  currentUser$: Observable<User>;
  months: Array<string> = AppGlobals.monthNames;
  matcher = new MyErrorStateMatcher();
  professionalServices = ProfessionalService;
  entityType = EntityType;
  selectedEntity: MergeTypes<BasicService, Entity>;
  basicService: BasicService;
  propertyArray = [EntityType.rent, EntityType.commonExpenses, EntityType.rent, EntityType.nursingHome,
  EntityType.mortgageFoot, EntityType.warehouse];
  automatePaymentForm: FormGroup;
  automatePaymentQueryParam = false;
  automatePayment: boolean;
  payingAccountUnit: PayingAccountUnit;
  private errorSubscription: Subscription;
  minNeatCost: number;
  cardTypes = CardTypes;
  maxNeatCost: number;
  UF: number;
  minDay: number;
  maxDay: number;
  currentUserIdToken: string;
  private valueChangesSubscriptions: Subscription = new Subscription();
  automaticPaymentDepositDayText: string;
  subcription: Subscription = new Subscription();
  canAutomatizateCurrentMonth = true;
  kushkiMode = false;
  isMobile: boolean;
  defaultTransferLimitDate: Date;
  customMaxAmountMessage = false;
  addingCard = false;
  showNeatAutoFeature = false;
  constructor(
    private rentListService: RentListService,
    private deviceService: DeviceDetectorService,
    private router: Router,
    public paymentClass: PaymentClass,
    private formValidation: FormValidation,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private checkoutService: CheckoutService,
    private userService: UserService,
    private paymentsService: PaymentsService,
    private errorService: ErrorHandlerService,
    private registerCreditCard: RegisterCreditCardService,
    private entityClass: EntityClass,
    private clpCurrencyPipe: ClpCurrencyPipe,
    private swalService: SweetalertService,
    private datepipe: DatePipe,
    private dialogService: DialogService,
    private location: Location,
    private db: DatabaseService,
    private cookiesService: CookiesService,
    private fireAnalytics: AngularFireAnalytics,
    public dialogRef: MatDialogRef<AutomatePaymentComponent>,
  ) {
    this.assignCurrentUserIdToken();
    this.currentUser$ = this.userService.currentUserData$;
    this.selectedEntity = this.rentListService.currentEntityForConfig;
    this.neatAutoFeature();
    if (!this.selectedEntity) {
      this.router.navigate(['/dashboard/']);
    }
    this.buildAutomateForm();
    this.formValueChanges();
    this.valueChangesSubscriptions.add(this.userService.currentUserData$.subscribe(currentUser => {
      this.currentUser = currentUser;
      this.setPaymentAutomatePaymentFormValues();
    }));
    if (this.selectedEntity?.payValidation && [2, 3, 5].includes(this.selectedEntity?.payValidation)) {
      this.setValidators(this.automatePaymentForm, 'sencillitoPreference');
    }
    if (this.selectedEntity?.payValidation && [1, 4, 6].includes(this.selectedEntity?.payValidation)) {
      if (this.selectedEntity.payValidation === 4) {
        this.automatePaymentForm.patchValue({
          sencillitoPreference: SencillitoAutomaticPreferences.total
        });
      } else {
        this.automatePaymentForm.patchValue({
          sencillitoPreference: SencillitoAutomaticPreferences.first
        });
      }
    }
    [CustomProvidersNames.hipotecariaSecurity,
    CustomProvidersNames.securityPrincipal].includes(this.selectedEntity?.customData?.providerName)
      ? this.definePaymentDateRangehipotecariaSecurity() : '';
    this.selectedEntity?.customData?.providerName === CustomProvidersNames.tango ? this.definePaymentDateRangeTango() : '';
    this.selectedEntity?.customData?.providerName === CustomProvidersNames.clubPolo ? this.definePaymentDateRangeClubPolo() : '';
    this.selectedEntity?.customData?.providerName === CustomProvidersNames.creditu ? this.definePaymentDateRangeCreditu() : '';
    if ((this.selectedEntity?.entityType as any) === BasicServiceType.basicService ||
      (this.selectedEntity?.customData?.providerName === CustomProvidersNames.edipro)) {
      this.removeValidators(this.automatePaymentForm, 'value');
      this.removeValidators(this.automatePaymentForm, 'valueUF');
      this.removeValidators(this.automatePaymentForm, 'payingAccountUnit');
    } else {
      this.getUFAmount();
    }
  }

  neatAutoFeature() {
    if (this.selectedEntity.utilityName && [1, 4, 6].includes(this.selectedEntity.payValidation) && this.rentListService.neatAutoAllowed) {
      this.showNeatAutoFeature = true;
    } else {
      this.showNeatAutoFeature = false;
    }
  }

  ngOnInit(): void {
    this.openAutomaticOptionWithQueryParam();
    this.isMobile = this.deviceService.isMobile();
    this.kushkiMode = this.userService.kushkiMode;
    this.subcription.add(this.rentListService.allUnifiedEntities$.subscribe((entities: any) => {
      const findEntity = entities.find(entity => entity.id === this.selectedEntity.id);
      if (findEntity) {
        this.selectedEntity = findEntity;
      }
    }));
  }

  openAutomaticOptionWithQueryParam(): void {
    this.route.queryParams.pipe(take(1)).subscribe(params => {
      params?.automate && (this.automatePaymentQueryParam = true);
    });
  }

  assignCurrentUserIdToken(): void {
    this.userService.idToken$.subscribe({
      next: (idToken) => this.currentUserIdToken = idToken,
      error: (error) => {
        this.errorService.recordError(error, 'checkout.component.ts',
          'this.userService.idToken$', 'Error al ejecutar subscribe de idToken$');
      }
    });
    this.userService.transferDates$.pipe(take(1)).subscribe(transferDates => {
      this.defaultTransferLimitDate = new Date(new Date(transferDates.transferDate.seconds * 1000).
        toLocaleString('en-US', { timeZone: 'America/Santiago' }));
    });
  }


  buildAutomateForm(): void {
    this.minNeatCost = AppGlobals.minNeatCost(this.selectedEntity.entityType);
    this.maxNeatCost = AppGlobals.maxNeatCost(this.selectedEntity.entityType, this.selectedEntity?.isVerified);
    this.automatePaymentForm = this.fb.group({
      transferLimitDate: ['', [Validators.required, Validators.min(1), Validators.max(31), this.formValidation.onlyNumberValidator]],
      paymentMethod: ['', Validators.required],
      alias: [''],
      sencillitoPreference: [''],
      lastTransferComment: [''],
      automaticType: [this.selectedEntity.utilityName && [1, 4, 6].includes(this.selectedEntity.payValidation) &&
        this.rentListService.neatAutoAllowed ? 'debtDriven' : ''],
      value: [
        0,
        [Validators.required, Validators.min(this.minNeatCost), Validators.max(this.maxNeatCost)]
      ],
      valueUF: [
        0, [Validators.required]
      ],
      payingAccountUnit: ['', Validators.required],
    });
  }

  checkFixedMaxAmount(value: number): void {
    if (value > 3_000_000 && [EntityType.others, EntityType.mortgageFoot, EntityType.socialClub, EntityType.rent,
    EntityType.sportClub].includes(this.selectedEntity?.entityType) && !this.selectedEntity?.isVerified) {
      this.customMaxAmountMessage = true;
    } else {
      this.customMaxAmountMessage = false;
    }
  }

  formValueChanges(): void {
    this.valueChangesSubscriptions.add(this.automatePaymentForm.get('transferLimitDate').valueChanges.subscribe(transferLimitDate => {
      if (transferLimitDate) {
        const automaticPaymentDates = this.checkoutService.automaticPaymentDates(this.automatePaymentForm.get('transferLimitDate')?.value);
        this.automaticPaymentDepositDayText = automaticPaymentDates?.depositDay;
        this.checkAutomateCurrentMonth(transferLimitDate);
      }
    }));

    this.valueChangesSubscriptions.add(this.automatePaymentForm.get('value').
      valueChanges.pipe(debounceTime(1000), distinctUntilChanged()).subscribe((value: number) => {
        this.automatePaymentForm.patchValue({
          valueUF: Number(AppGlobals.convertPesoToUF(this.UF, value).toFixed(2))
        }, { emitEvent: false });
        this.checkFixedMaxAmount(value);
      }));

    this.valueChangesSubscriptions.add(this.automatePaymentForm.get('valueUF').
      valueChanges.pipe(debounceTime(1000), distinctUntilChanged()).subscribe((valueUF: number) => {
        this.automatePaymentForm.patchValue({
          value: Math.round(AppGlobals.convertUFToPeso(this.UF, valueUF))
        }, { emitEvent: false });
        this.checkFixedMaxAmount(Math.round(AppGlobals.convertUFToPeso(this.UF, valueUF)));
      }));

    this.valueChangesSubscriptions.add(this.automatePaymentForm.get('payingAccountUnit').valueChanges.subscribe(payingAccountUnit => {
      this.payingAccountUnit = PayingAccountUnit[payingAccountUnit];
    }));
  }

  checkAutomateCurrentMonth(selectedDay?: number) {
    const fastPayment = this.selectedEntity?.customData || this.selectedEntity?.utilityName ? true : false;
    this.canAutomatizateCurrentMonth = canAutomatizatePayment(selectedDay, fastPayment);
  }

  getUFAmount() {
    this.valueChangesSubscriptions = this.paymentsService.UF$.subscribe({
      next: async (uf) => {
        if (uf) {
          this.UF = uf;
          this.setUFAsyncValidator(this.automatePaymentForm, 'valueUF', uf);
          this.setPaymentFormAmountFields(uf);
        } else {
          this.getUFWhenSubscribeFail();
        }
      },
      error: (error) => {
        this.getUFWhenSubscribeFail();
        this.errorService.recordError(error, 'entity-box.component.ts', 'this.paymentsService.UF$', 'Error al ejecutar subscribe de UF$');
      }
    });
  }

  async getUFWhenSubscribeFail() {
    const ufAmount = (await this.paymentsService.getUFFromFirebase()).UF;
    this.UF = ufAmount;
    this.setUFAsyncValidator(this.automatePaymentForm, 'valueUF', ufAmount);
    this.setPaymentFormAmountFields(ufAmount);
  }

  setUFAsyncValidator(formGroup: FormGroup | AbstractControl, field: string, uf: number) {
    formGroup.get(field).setValidators([
      Validators.min(
        Number(AppGlobals.convertPesoToUF(uf, this.minNeatCost).toFixed(2))),
      Validators.max(
        Number(AppGlobals.convertPesoToUF(uf, this.maxNeatCost).toFixed(2))
      )]);
    if (formGroup.get(field).value === null || formGroup.get(field).value === '') {
      formGroup.get(field).setErrors({ required: true });
    }
  }

  setMinAndMaxValueValidatorSameMonths(formGroup: FormGroup | AbstractControl, field: string, minValue: number, maxValue: number) {
    formGroup.get(field).setValidators([
      FormValidation.sameMonthValidator(minValue, maxValue)
    ]);
    if (formGroup.get(field).value === null || formGroup.get(field).value === '') {
      formGroup.get(field).setErrors({ required: true });
    }
  }

  setMinAndMaxValueValidatorDiffMonths(formGroup: FormGroup | AbstractControl, field: string, minValue: number, maxValue: number) {
    formGroup.get(field).setValidators([
      FormValidation.differentMonthValidator(minValue, maxValue)
    ]);
    if (formGroup.get(field).value === null || formGroup.get(field).value === '') {
      formGroup.get(field).setErrors({ required: true });
    }
  }

  private async setPaymentFormAmountFields(uf: number) {
    if (this.selectedEntity.payingAccountUnit === PayingAccountUnit.UF) {
      this.payingAccountUnit = PayingAccountUnit.UF;
      this.automatePaymentForm.patchValue({
        valueUF: Number(this.selectedEntity.valueUF.toFixed(2)),
        value: Math.round(AppGlobals.convertUFToPeso(uf, this.selectedEntity.valueUF)),
        payingAccountUnit: PayingAccountUnit.UF
      }, { emitEvent: false });
    } else if (this.selectedEntity.payingAccountUnit === PayingAccountUnit.CLP) {
      this.payingAccountUnit = PayingAccountUnit.CLP;
      this.automatePaymentForm.patchValue({
        valueUF: Number(AppGlobals.convertPesoToUF(uf, this.selectedEntity.value).toFixed(2)),
        value: Math.round(this.selectedEntity.value),
        payingAccountUnit: PayingAccountUnit.CLP
      }, { emitEvent: false });
    }
  }

  private setPaymentAutomatePaymentFormValues(): void {
    if (this.selectedEntity?.paymentOption?.automatic) {
      this.automatePayment = this.selectedEntity?.paymentOption?.automatic;
    }
    if (this.currentUser?.paymentMethods && this.currentUser?.paymentMethods.length === 1) {
      this.automatePaymentForm.patchValue({
        paymentMethod: this.currentUser?.paymentMethods[0].id
      }, { emitEvent: true });
    } else if (this.currentUser?.paymentMethods && this.currentUser?.paymentMethods?.find(paymentMethod =>
      paymentMethod.id === this.selectedEntity.paymentOption?.paymentMethodId)) {
      this.automatePaymentForm.patchValue({
        paymentMethod: this.selectedEntity.paymentOption?.paymentMethodId
      }, { emitEvent: true });
    } else if (this.currentUser?.preferredPaymentMethods?.preferredCardForAutomate && !this.selectedEntity.paymentOption?.automatic) {
      this.automatePaymentForm.patchValue({
        paymentMethod: this.currentUser?.preferredPaymentMethods?.preferredCardForAutomate ?
          this.currentUser.preferredPaymentMethods?.preferredCardForAutomate : this.selectedEntity.paymentOption?.paymentMethodId
      }, { emitEvent: true });

    }

    if (this.selectedEntity?.transferLimitDate) {
      this.automatePaymentForm.patchValue({
        transferLimitDate: this.selectedEntity.transferLimitDate
      });
    }

    if (this.selectedEntity?.paymentOption?.sencillitoPreference) {
      this.automatePaymentForm.patchValue({
        sencillitoPreference: this.selectedEntity?.paymentOption?.sencillitoPreference,
      }, { emitEvent: true });
    }

    if (this.selectedEntity.lastTransferComment) {
      this.automatePaymentForm.patchValue({
        lastTransferComment: this.selectedEntity.lastTransferComment,
      }, { emitEvent: true });
    }

    if (this.selectedEntity.alias) {
      this.automatePaymentForm.patchValue({
        alias: this.selectedEntity.alias,
      }, { emitEvent: true });
    }

    if (this.selectedEntity?.paymentOption?.automaticType || this.selectedEntity?.paymentOption.automatic) {
      this.automatePaymentForm.patchValue({
        automaticType: this.selectedEntity?.paymentOption?.automaticType ?
          this.selectedEntity?.paymentOption?.automaticType : 'scheduled',
      }, { emitEvent: true });
    }
  }

  definePaymentDateRangehipotecariaSecurity(): void {
    this.removeValidators(this.automatePaymentForm, 'value');
  }


  definePaymentDateRangeTango(): void {
    this.removeValidators(this.automatePaymentForm, 'value');
    const firstDayAvailableForPay = customProvidersDayPaymentLimits.tango.start;
    const lastDayAvailableForPay = customProvidersDayPaymentLimits.tango.end;
    this.minDay = customProvidersDayPaymentLimits.tango.start;
    this.maxDay = customProvidersDayPaymentLimits.tango.end;
    this.setMinAndMaxValueValidatorSameMonths(this.automatePaymentForm, 'transferLimitDate',
      firstDayAvailableForPay, lastDayAvailableForPay);
  }

  definePaymentDateRangeClubPolo(): void {
    this.removeValidators(this.automatePaymentForm, 'value');
    const firstDayAvailableForPay = customProvidersDayPaymentLimits.clubPolo.start;
    this.minDay = customProvidersDayPaymentLimits.clubPolo.start;
    this.maxDay = customProvidersDayPaymentLimits.clubPolo.end;
    this.setMinAndMaxValueValidatorDiffMonths(this.automatePaymentForm, 'transferLimitDate',
      firstDayAvailableForPay, customProvidersDayPaymentLimits.clubPolo.end);
  }

  definePaymentDateRangeCreditu(): void {
    this.removeValidators(this.automatePaymentForm, 'value');
  }


  removeValidators(formGroup: FormGroup | AbstractControl, field: string) {
    formGroup.get(field).setErrors(null);
    formGroup.get(field).clearValidators();
    formGroup.get(field).markAsPristine();
    formGroup.get(field).setValue(null);
  }

  formatCreditCardNumbers(text: any) {
    const lastNumbers = text.substr(text.length - 4);
    return `•••• ${lastNumbers}`;
  }

  setPaymentMethodId(paymentMethodId: string) {
    this.automatePaymentForm.patchValue({
      paymentMethod: paymentMethodId
    });
  }

  addCreditCards(): void {
    this.kushkiMode ? this.dialogService.openKushkiDialog(this.isMobile) : this.creditCardRegistration();
  }

  async creditCardRegistration() {
    this.addingCard = true;
    this.showLoading = true;
    this.cookiesService.setCookie('editEntity', JSON.stringify(this.selectedEntity), '.neatpagos.com', 0.3);
    const defineHomePath = 'home';
    const res = await this.registerCreditCard.getCreditCardRegistrationToken(this.currentUserIdToken, defineHomePath);
    if (res === false) {
      this.showLoading = false;
    }
    this.errorSubscription = this.registerCreditCard.detectErrorOnRequest$.subscribe(error => {
      if (error) {
        this.showLoading = false;
        this.swalService.swalError2('No se ha podido agregar tu tarjeta',
          `Esto se debe a problemas con tu conexión a internet. Por favor intenta con otra red Wifi. 
        Si estás conectado al internet de tu compañía de celular, inténtalo de nuevo más tarde.`, IconErrorModals.sadCard, true, false);
        this.errorSubscription.unsubscribe();
      }
    });
  }
  async submitAutomatePayment(pinActive: boolean, automatic: boolean) {
    let checkPaymentAfterAutomate: boolean;
    const defaultTransferLimitDate = this.defaultTransferLimitDate;
    const isPinActive = pinActive ? true : false;
    const paymentTextArrayLength = this.automaticPaymentDepositDayText?.split(' ')?.length;
    const month = this.automaticPaymentDepositDayText?.split(' ')[paymentTextArrayLength - 1].toLocaleLowerCase();
    if (this.automatePaymentForm.get('automaticType').value === 'debtDriven') {
      this.removeValidators(this.automatePaymentForm, 'transferLimitDate');
      this.automatePaymentForm.patchValue({
        transferLimitDate: null
      });
    } else {
      this.setValidators(this.automatePaymentForm, 'transferLimitDate');
    }
    const savingEntity = this.selectedEntity?.utilityName ?
      this.entityClass.updateEntityAutomateBasicService(this.automatePaymentForm, automatic) :
      this.entityClass.updateEntityAutomatePayment(this.automatePaymentForm, automatic);
    const checkChanges = this.checkUpdateChanges(savingEntity);
    if (automatic === false) {
      this.removeValidators(this.automatePaymentForm, 'paymentMethod');
    } else {
      this.setValidators(this.automatePaymentForm, 'paymentMethod');
    }
    if (this.automatePaymentForm.invalid) {
      if (this.automatePaymentForm.get('paymentMethod').status !== 'VALID') {
        this.swalService.swalError(``, `El método de pago es requerido`);
      } else if (this.automatePaymentForm.get('transferLimitDate').status !== 'VALID') {
        this.swalService.swalError(``, `El día de pago es requerido`);
      } else if (this.automatePaymentForm.get('sencillitoPreference').status !== 'VALID') {
        this.swalService.swalError(``, `Debes seleccionar una forma de pago`);
      } else if (this.automatePaymentForm.get('value').status !== 'VALID') {
        this.swalService.swalError(``, `El monto es requerido`);
      } else if (this.automatePaymentForm.get('valueUF').status !== 'VALID') {
        this.swalService.swalError(``, `El monto es requerido`);
      } else if (this.automatePaymentForm.get('payingAccountUnit').status !== 'VALID') {
        this.swalService.swalError(``, `El tipo de moneda es requerido`);
      }
      return false;
    }
    if (!checkChanges) {
      this.swalService.swalInfo(``, `No se detectaron cambios`);
      return false;
    }
    if (!this.canAutomatizateCurrentMonth && automatic && (this.selectedEntity.value > 0 ||
      this.automatePaymentForm.get('value').value > 0)) {
      const swalInfoFalabella = await this.swalService.swalInfoOutdatedAutomaticPayment(
        'Ups, estamos muy encima de la fecha de transferencia',
        `<div class="text-left">
       <p class="modal-text-content-1">No alcanzamos a automatizar tu pago este mes</p>
       <p class="modal-text-content-1">Puedes dejar tu pago automático para que sea depositado el próximo 
       ${this.automaticPaymentDepositDayText}</p>
       <p class="modal-text-content-1">También puedes ir a pagar hoy ${!this.selectedEntity?.customData &&
          !this.selectedEntity?.utilityName ? `, el pago será transferido el ${defaultTransferLimitDate.getDate()} de 
        ${this.transformDateMonth(defaultTransferLimitDate)} y dejarás tu pago automático para el próximo mes` :
          ' y dejarás tu pago automático para el próximo mes'}</p></div>`, IconInfoModals.alertIcon,
        `Sólo automatizar desde ${month}`, `Ir a pagar hoy y automatizar desde ${month}`);
      if (swalInfoFalabella.value) {
        checkPaymentAfterAutomate = true;
      } else if (swalInfoFalabella.dismiss === Swal.DismissReason.cancel) {
        checkPaymentAfterAutomate = false;
      } else {
        return false;
      }
    }
    if (isPinActive) {
      this.dialogService.openPinValidateDialog().afterClosed().subscribe(async result => {
        if (result?.pinValid) {
          await this.updateEntity(automatic, savingEntity);
          if (checkPaymentAfterAutomate) {
            if (this.selectedEntity?.customData || this.selectedEntity?.utilityName) {
              this.goToMultipay([this.selectedEntity]);
            } else {
              this.goToPay(this.selectedEntity);
            }
          }
        }
      });
    } else {
      await this.updateEntity(automatic, savingEntity);
      if (checkPaymentAfterAutomate) {
        if (this.selectedEntity?.customData || this.selectedEntity?.utilityName) {
          this.goToMultipay([this.selectedEntity]);
        } else {
          this.goToPay(this.selectedEntity);
        }
      }
    }
  }

  async updateEntity(automatic: boolean, savingEntity: any): Promise<void | boolean> {
    this.showLoading = true;
    try {
      if (this.selectedEntity?.utilityName) {
        if (!savingEntity.automaticType) {
          await this.checkoutService.automateBasicServiceNewAndDeleteField((this.selectedEntity as any), savingEntity, true);
        } else {
          await this.checkoutService.automateBasicServiceNew((this.selectedEntity as any), savingEntity, true);
        }
      } else {
        await this.checkoutService.automatePayment(this.selectedEntity, savingEntity, true);
      }
      if (this.automatePaymentForm.get('transferLimitDate').value) {
        const automaticPaymentDates = this.checkoutService.automaticPaymentDates(this.automatePaymentForm.get('transferLimitDate').value);
        this.automaticPaymentDepositDayText = automaticPaymentDates.depositDay;
      }
      if (this.selectedEntity?.customData || this.selectedEntity?.utilityName) {
        if (this.automatePaymentForm.get('automaticType').value === 'debtDriven') {
          this.swalService.swalSuccess(
            `<span style="font-weight: 600; font-size: 22px; text-align: center; color: #000000;">
            Tus cambios se han guardado con éxito</span>`,
            `<div class="text-left ml-3">Nosotros nos encargaremos de pagar tu cuenta cada vez que se actualice la deuda. <br/>
            Te avisaremos por correo un día antes de hacer el pago.
            </div>`);
          !this.isMobile ? this.dialogRef.close() : this.router.navigate([`/dashboard/configurar-cuenta/${this.selectedEntity.id}`]);
        } else if (!this.selectedEntity?.paymentOption.automatic && automatic) {
          this.rentListService.selectedNav = 3;
          this.editAutomaticEntity = false;
          this.swalService.swalSuccess(
            `<span style="font-weight: 600; font-size: 22px; text-align: center; color: #000000;">
            Tu cuenta se ha automatizado exitosamente</span>`,
            `<div class="text-left ml-3">Tu cuenta se ha automatizado para pagar: <br/><ul><li>Los <b>días 
            ${savingEntity.transferLimitDate}</b></li><li>El monto entregado por <b>${this.selectedEntity?.utilityName ?
              this.selectedEntity?.utilityName : this.selectedEntity?.ownerFullName}</b></li><li>Por concepto de <b>
              ${this.selectedEntity?.utilityName ? this.selectedEntity?.utilityName : this.selectedEntity?.ownerFullName}
              </b></li></ul><br/>*Puedes editar estos datos y desactivar el pago automático, cuando quieras, desde mis cuentas.</div>`);
          !this.isMobile ? this.dialogRef.close() : this.router.navigate([`/dashboard/configurar-cuenta/${this.selectedEntity.id}`]);
        } else if (this.selectedEntity?.paymentOption?.automatic && automatic) {
          this.rentListService.selectedNav = 3;
          this.editAutomaticEntity = false;
          this.swalService.swalSuccess(
            `<span style="font-weight: 600; font-size: 22px; text-align: center; color: #000000;">
              Tus cambios se han guardado con éxito</span>`,
            `<div class="text-left ml-3">Tu cuenta se ha automatizado para pagar: 
              <br/><ul><li>Los <b>días ${savingEntity.transferLimitDate}</b></li><li>
              El monto entregado por <b>${this.selectedEntity?.utilityName ?
              this.selectedEntity?.utilityName : this.selectedEntity?.ownerFullName}</b></li><li>
                Por concepto de <b>${this.selectedEntity?.utilityName ? this.selectedEntity?.utilityName :
              this.selectedEntity?.ownerFullName}</b></li></ul></div>`);
          !this.isMobile ? this.dialogRef.close() : this.router.navigate([`/dashboard/configurar-cuenta/${this.selectedEntity.id}`]);
        }
      } else {
        if (!this.selectedEntity?.paymentOption?.automatic && automatic) {
          this.rentListService.selectedNav = 3;
          this.editAutomaticEntity = false;
          this.swalService.swalSuccess(
            '<span style="font-weight: 600; font-size: 22px; text-align: center; color: #000000;">Tu cuenta se ha automatizado exitosamente</span>',
            `<div class="text-left ml-3">Tu cuenta se ha automatizado para pagar: <br/><ul><li>Los <b>días ${savingEntity.transferLimitDate}</b></li>
            <li>El monto de <b>${savingEntity.payingAccountUnit === PayingAccountUnit.CLP ? `${this.clpCurrencyPipe.transform(savingEntity.value)} ${PayingAccountUnit.CLP}` : `${savingEntity.valueUF} ${PayingAccountUnit.UF}`}</b></li>
            <li>Por concepto de <b>${EntityTypeLocale[this.selectedEntity?.entityType]}</b></li></ul><br/>*Puedes editar estos datos y desactivar el pago automático, cuando quieras, desde mis cuentas en la pestaña de automatizadas.</div>`
          );
          !this.isMobile ? this.dialogRef.close() : this.router.navigate([`/dashboard/configurar-cuenta/${this.selectedEntity.id}`]);
        } else if (this.selectedEntity?.paymentOption?.automatic && automatic) {
          this.rentListService.selectedNav = 3;
          this.editAutomaticEntity = false;
          this.swalService.swalSuccess('<span style="font-weight: 600; font-size: 22px; text-align: center; color: #000000;">Tus cambios se han guardado con éxito</span>', `<div class="text-left ml-3">Tu cuenta se ha automatizado para pagar: <br/><ul><li>Los <b>días ${savingEntity.transferLimitDate}</b></li><li>El monto de <b>${savingEntity.payingAccountUnit === PayingAccountUnit.CLP ? `${this.clpCurrencyPipe.transform(savingEntity.value)} ${PayingAccountUnit.CLP}` : `${savingEntity.valueUF} ${PayingAccountUnit.UF}`}</b></li><li>Por concepto de <b>${EntityTypeLocale[this.selectedEntity?.entityType]}</b></li></ul></div>`);
          !this.isMobile ? this.dialogRef.close() : this.router.navigate([`/dashboard/configurar-cuenta/${this.selectedEntity.id}`]);
        }
      }
      this.fireAnalytics.logEvent('AutomatePaymentFromHomeSection', {
        type: this.selectedEntity?.entityType,
        action: 'Automate',
        fromModal: this.automatePaymentQueryParam
      });
      this.showLoading = false;
    } catch (error) {
      this.errorWithEntityUpdate(error, savingEntity);
      throw error;
    }
  }

  transformDateMonth(date: Date) {
    return this.datepipe.transform(date, 'MMMM', '', 'es-CL');
  }

  errorWithEntityUpdate(error: any, savingEntity: any): void {
    if (this.selectedEntity?.paymentOption && this.selectedEntity?.paymentOption?.automatic) {
      this.swalService.swalError2(
        'Ha ocurrido un error al cargar los datos del pago',
        'Por favor inténtalo de nuevo. Si no funciona escríbenos en el chat.',
        IconErrorModals.sadCloud,
        false,
        true
      );
    } else {
      this.swalService.swalError2(
        'No se pudo automatizar tu cuenta',
        'Inténtalo de nuevo, si el problema persiste, escríbenos al chat.',
        IconErrorModals.sadCloud,
        false,
        true
      );
    }
    this.errorService.recordError(
      error,
      'entity-box.component.ts',
      'this.db.firestoreUpdateEntity()',
      `Error al actualizar entidad from updateEntity(): ${JSON.stringify(this.selectedEntity)} ` +
      `y los datos de salida son: ${JSON.stringify(savingEntity)}`,
      Severity.Fatal
    );
    this.showLoading = false;
  }

  checkUpdateChanges(savingEntity: any) {
    if (savingEntity.value === this.selectedEntity?.value && savingEntity.transferLimitDate === this.selectedEntity?.transferLimitDate &&
      savingEntity.payingAccountUnit === this.selectedEntity?.payingAccountUnit &&
      savingEntity.paymentOption?.paymentMethodId === this.selectedEntity?.paymentOption?.paymentMethodId &&
      savingEntity.paymentOption.automatic === this.selectedEntity?.paymentOption?.automatic &&
      savingEntity.lastTransferComment === this.selectedEntity?.lastTransferComment) {
      return false;
    }
    else {
      return true;
    }
  }

  goToPay(entity: Entity) {
    this.rentListService.currentEntity = entity;
    this.rentListService.selectedEntities = [entity] as MergeTypes<BasicService, Entity>[];
    if (this.currentUser?.webCheckoutVersion === 2 || !this.currentUser?.webCheckoutVersion || today > new Date('2023-06-01T00:00:00')) {
      this.router.navigate(['/dashboard/pagar-nuevo', entity?.id]);
    } else {
      this.router.navigate(['/dashboard/pagar', entity?.id]);
    }
  }

  goToMultipay(entities: MergeTypes<BasicService, Entity>[]) {
    this.rentListService.selectedEntitiesForMultipay = entities;
    this.rentListService.selectedEntities = entities;
    if (this.currentUser?.webCheckoutVersion === 2 || !this.currentUser?.webCheckoutVersion || today > new Date('2023-06-01T00:00:00')) {
      this.router.navigate(['/dashboard/pagar-nuevo', entities[0]?.id]);
    } else {
      this.router.navigate(['/dashboard/multipago', entities[0]?.id]);
    }
  }

  askForDeleteEntity(entityId: string, entityType: EntityType) {
    this.swalService.swalConfirmationQuestion(
      '¿Estás seguro que deseas eliminar?',
      'No podrás volver atrás, te recordamos que si tienes esta cuenta en proceso de pago, ' +
      'el pago será realizado igualmente y no se verá afectado al borrar la cuenta.'
    ).then(async result => {
      if (result.value) {
        this.showLoading = true;
        if (this.selectedEntity?.utilityName) {
          await this.rentListService.deleteBasicService(entityId, entityType).finally(() => {
            this.rentListService.currentEntityForConfig = null;
            this.router.navigate(['/dashboard/home']).then(() => {
              this.swalService.swalToastGeneral('¡Eliminado!, Se ha eliminado correctamente', 'success');
            });
          });
        } else {
          await this.entityClass.deleteEntity(entityId, entityType).finally(() => {
            this.rentListService.currentEntityForConfig = null;
            this.router.navigate(['/dashboard/home']).then(() => {
              this.swalService.swalToastGeneral('¡Eliminado!, Se ha eliminado correctamente', 'success');
            });
          });
        }
      }
    }).catch(error => {
      this.errorService.recordError(error, 'entity-box.component.ts',
        'askForDelete()', 'Error al confirmar eliminación de entidad');
    });
  }

  setValidators(formGroup: FormGroup | AbstractControl, field: string) {
    formGroup.get(field).setValidators([Validators.required]);
    if (formGroup.get(field).value === null || formGroup.get(field).value === '') {
      formGroup.get(field).setErrors({ required: true });
    }
  }

  goBack(): void {
    this.location.back();
  }

  selectSencillitoPreference(type: string): void {
    this.automatePaymentForm.patchValue({
      sencillitoPreference: type
    });
  }

  formatBankName(bankName: string, cardType?: string): paymentMethodsOptions | string {
    if (bankName) {
      const formatBankName = bankName.toLocaleLowerCase()
        .replace(/ /g, '')
        .replace(/\//g, '')
        .replace(/\./g, '')
        .replace(/-/g, '')
        .replace(/\)/g, '')
        .replace(/\(/g, '');
      const findBankName = paymentMethodsOptions[formatBankName];
      if (cardType === 'Redcompra' && paymentMethodsOptions.scotiabank === findBankName) {
        return findBankName + cardType.toLocaleLowerCase();
      }
      if (
        cardType === 'Prepago' &&
        [
          paymentMethodsOptions.bancodecreditoeinversiones,
          paymentMethodsOptions.bancosantanderchile,
          paymentMethodsOptions.bancoripley
        ].includes(findBankName)
      ) {
        return findBankName + cardType.toLocaleLowerCase();
      }
      return findBankName;
    }
  }

  formatBankNameClass(bankName: string, cardType?: string): paymentMethodsOptions | string {
    if (bankName) {
      const formatBankName = bankName.toLocaleLowerCase().replace(/\ /g, '').replace(/\//g, '').replace(/\./g, '').replace(/\-/g, '').replace(/\)/g, '').replace(/\(/g, '');
      const findBankName = paymentMethodsOptions[formatBankName];
      if (cardType === 'Prepago' && [paymentMethodsOptions.bancodecreditoeinversiones, paymentMethodsOptions.bancosantanderchile, paymentMethodsOptions.bancoripley].includes(findBankName)) {
        return `${findBankName}-${cardType.toLocaleLowerCase()}`;
      }
      return findBankName;
    }
  }

  formatProviderName(provider: string): string {
    return formatEdipro(provider, true);
  }

  close(): void {
    this.dialogRef.close();
  }
}

